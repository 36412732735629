import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import DefaultLoadingComponent from '../Loading';
import SpinnerService from '../../../services/SpinnerService';

class Module extends Component {
    state = { Component: DefaultLoadingComponent, componentProps: null };

    componentDidMount() {
        const { getComponent, spinnerMessage = false, hideSpinner = false, authorized = true, ...otherProps } = this.props;

        if (authorized) {
            // Update Root Loader message
            if (spinnerMessage) SpinnerService.update(spinnerMessage);

            // Lazy load the component into the state
            this.setState({
                Component: lazy(() => getComponent().then(mod => {
                    // Extract the reducer, key & component
                    const { reducer, key, component } = mod.default;

                    // Inject the reducer to the store
                    if (reducer) window.app.store.addReducer(key, reducer);

                    // Hide Root Loader
                    if (hideSpinner) SpinnerService.hide();

                    // Return the component as an esModule
                    return { default: component }
                })),
                componentProps: otherProps
            })
        }
    }

    render() {
        const { authorized = true, loading = {} } = this.props;
        const { Component, componentProps } = this.state;
        const { animated = false, text = "Loading", show = true } = loading;

        if (authorized)
            return (
                <Suspense fallback={<DefaultLoadingComponent animated={animated} text={text} show={show} />}>
                    <Component {...componentProps} />
                </Suspense>
            )
        else return false;
    }
};

// Mappings

/* istanbul ignore next */
const mapStateToProps = (state, { feature = false }) => {
    const allFeatures = state.Session ? state.Session.features : {};
    const authorized = !feature || (feature && !!allFeatures[feature]);
    return { authorized };
};

export { Module };
// NOTE: Might need to add the withRouter if something is not working correctly
// export default withRouter(connect(mapStateToProps, null)(Module));
export default connect(mapStateToProps, null)(Module);