import React from 'react';
import ReactDOM from 'react-dom';

const getElement = () => document.getElementById('root-loader');
const getInnerElement = () => document.getElementById('root-loader-inner');
const hiddenClass = 'hidden';

/**
 * Updates the loader message
 * @param {string} text - new message for the loader
 */
const update = (text) => {
    ReactDOM.render(<p>{text}</p>, getInnerElement());
    show();
}

/**
 * Shows the root-loader by removing the hidden class;
 */
const show = () => getElement().classList.remove(hiddenClass);

/**
 * Hides the root-loader by adding the hidden class;
 */
const hide = () => getElement().classList.add(hiddenClass);

export {
    update,
    show,
    hide
}

export default {
    update,
    show,
    hide
}