import i18n from "i18next";
import { reactI18nextModule } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import translations from './translations';

let missingKeys = "";
const { NODE_ENV } = process.env;

i18n
//import translations externally
    .use(Backend)
    .use(reactI18nextModule)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            ...translations
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        debug: (NODE_ENV !== "production") ? true : false,
        saveMissing: true,
        missingKeyHandler: (lng, ns, key ,fallbackValue) => {
            let parts = key.split(".");
            let context = ""
            let term = ""
            parts.forEach((part, i) => {
                if(i < parts.length-1) {
                    context += `"${part}".`
                } else {
                    term = part
                }
            });
            context = context.substring(0, context.length-1);
            if(!missingKeys.includes(`${term}\t${context}`)) {
                missingKeys += `${term}\t${context}\n`;
            }
        }
    });

    // DEBUG
    if(NODE_ENV !== "production") {
        setTimeout(() => {
            if(missingKeys) {
                console.log(missingKeys);
                throw new Error(`Missing Keys for translation:\n${missingKeys}`);
            }
        }, 5000);
    }

export default i18n;