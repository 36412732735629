import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Define the Reducers that will always be present in the appication
const staticReducers = {
    Main: (store = {}) => store
};

// Compose middleware & other store enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true})) : compose;
const middleware = composeEnhancers(applyMiddleware(thunk));

// Handy function for combining dynamic reducers with the static reducers
function createReducer(dynamicReducers) {
    return combineReducers({
        ...staticReducers,
        ...dynamicReducers
    });
};

// Create the store
const store = createStore(createReducer(), middleware);

// Add a dictionary to keep track of the registered dynamic reducers
store.dynamicReducers = {};

// Add reducer function
// This function adds the dynamic reducer, and creates a new combined reducer
store.addReducer = (key, dynamicReducer) => {
    // Check if it wasn't added already
    if (!store.dynamicReducers[key]) {
        store.dynamicReducers[key] = dynamicReducer
        store.replaceReducer(createReducer(store.dynamicReducers))
        // console.log(`Attaching ${key} to the store`)
    }
};

// Remove reducer function
// This function removes an dynamic reducer, and creates a new combined reducer
store.removeReducer = (key) => {
    // Check if it is added
    if (store.dynamicReducers[key]) {
        delete store.dynamicReducers[key];
        store.replaceReducer(createReducer(store.dynamicReducers))
    }
};

// Return the store
export default store;