export const base = {
    admin: '/administration',
    ims: '/incidents',
    dashboard: '/dashboard',
    support: '/support',
    profile: '/profile',
    billing: '/billing',
    searchlight: '/searchlight'
};

export const url = {
    admin: {
        home: base.admin,
        team: `${base.admin}/team`,
        company: `${base.admin}/company`,
        registrations: `${base.admin}/team/invites`,
        createUser: `${base.admin}/team/create`,
        editUser: `${base.admin}/team/:id/edit`
    },
    ims: {
        dashboard: {
            home: `${base.ims}/dashboard`,
            create: `${base.ims}/dashboard/create`,
            updateLayout:`${base.ims}/dashboard/update-layout`,
            shareDashboard: `${base.ims}/dashboard/share`,
            staticDashboard: `${base.ims}/dashboard/static/:token`,
            filter: `${base.ims}/dashboard/filter`,
            allowAccessToDashboard: `${base.ims}/dashboard/allow/:token`
        },
        uploads: {
            home: `${base.ims}/uploads`,
            add: `${base.ims}/uploads/add`,
            summary: `${base.ims}/uploads/:id/summary`,
            createTemplate: `${base.ims}/uploads/create-template`
        },
        incidents:  {
            home: `${base.ims}/database`,
            edit: `${base.ims}/database/:id/edit`,
            create: `${base.ims}/database/create`,
            types: {
                createSubtype: `${base.ims}/types/:id/create`,
                create: `${base.ims}/types/create`,
                read: `${base.ims}/types`,
                update: `${base.ims}/types/:id/edit`,
            }
        }
    },
    support: {
        company: `${base.support}/companies`,
        createCompany: `${base.support}/companies/create`,
        editCompany: `${base.support}/companies/:id/edit`,
        users: `${base.support}/users`,
        createUser: `${base.support}/users/create`,
        editUser: `${base.support}/users/:id/edit`
    },
    profile: {
        home: `${base.profile}`,
        edit: `${base.profile}/details/edit`,
        security: `${base.profile}/security/edit`,
    },
    billing: {
        home: `${base.billing}`,
        history: `${base.billing}/history`
    },
    searchlight: {
        home: `${base.searchlight}/map`,
        staff: {
            home:  `${base.searchlight}/staff`,
            create: `${base.searchlight}/staff/create`,
        },
        library: {
            home: `${base.searchlight}/library`,
            upload: `${base.searchlight}/library/upload`
        }
    }

};