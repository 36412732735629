import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import SessionModule from 'modules/SessionModule';
import UserActivationModule from 'modules/UserActivationModule';
import StaticDashboardModule from "platforms/IMS/static/StaticDashboardModule";
import store from 'store';
import { Provider } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import 'antd/dist/antd.css';
import 'framework/styles/main.scss';
import {NotificationContainer} from 'react-notifications';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import ReactGA from 'react-ga';
import {MetaTags} from 'react-meta-tags';
import { url } from 'constants/url';


// TODO: remove this when react-redux team makes it available to get the store from the context
window.app = { store };

if(process.env.REACT_APP_ENVIROMENT === 'production') {
    ReactGA.initialize([{
            trackingId: process.env.REACT_APP_GOOGLE_TAG,
            gaOptions: {
                userId: (store.getState() && store.getState().Session && store.getState().Session.data && store.getState().Session.data.user && store.getState().Session.data.user.id) ? store.getState().Session.data.user.id : null,
                titleCase: false
            }
        }]
    );
}

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            {process.env.REACT_APP_ENVIROMENT === 'production' && <MetaTags> <meta name="insight-app-sec-validation" content="215dee72-f530-4255-a918-f77ea6d39fe9"/> </MetaTags>}
            <Router>
                <Switch>
                    <Route path="/activate/:token" component={UserActivationModule} />
                    <Route path="/activate/" component={UserActivationModule} />
                    <Route path={url.ims.dashboard.staticDashboard} component={StaticDashboardModule} />
                    <Route component={SessionModule} />
                </Switch>
            </Router>
            <NotificationContainer />
        </Provider>
    </I18nextProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
