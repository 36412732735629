import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import i18next from 'i18next';

const StyledLoading = styled.div`
    width: 100%;
    text-align: center;
    top: 40px;
    display: block;
    position: relative;
`;

const StyledLoadingText = styled.div`
    font-size: 1.25em;
    color: #3a414d99;
    margin-top: 15px;
`;

const Loading = ({ text = i18next.t('load_states.loading'), animated = false, show = true, size = 70 }) => {
    const textComponent = <StyledLoadingText>{text}</StyledLoadingText>;

    if (!show)
        return null;
    else if (animated)
        return (
            <StyledLoading>
                <ClipLoader
                    sizeUnit={"px"}
                    size={size}
                    color={'#292a2d'} />
                {textComponent}
            </StyledLoading>
        )
    else
        return textComponent;
};

export default Loading;